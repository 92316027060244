import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './hover.css'
// import Error from '../components/Error'
// import Spinner from '../components/Spinner'
import { registerUser } from '../features/register/registerSlice';

function Register() {
    // Theme
    const darkmode = useSelector((state) => state.theme.darkmode);

    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [buttonVal, setButtonVal] = useState('Register');
    const canRegister = Boolean(name) && Boolean(email) && Boolean(password) && Boolean(confirmPassword)

    const handleRegister = () => {
        setButtonVal('Processing...')
        dispatch(registerUser({ name, email, password, confirmPassword }));
        resetCredentials()
    };

    //Cancel Login
    function resetCredentials() {
        setName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    }
    return (
        <div div className="modal fade" id="singUpModal" aria-hidden="true" aria-labelledby="signUpModal" tabIndex="-1" >
            <div className="modal-dialog modal-dialog-centered">
                <div className={` modal-content shadow-lg ${darkmode ? "bg-dark" : ""}`}>
                    <div className="modal-header">
                        <h3 className={`col-12 modal-title text-center ${darkmode ? "text-white" : "text-dark"}`} id="loginModalText">
                            Register
                        </h3>
                    </div>
                    <form>
                        <div className="modal-body">
                            <div className="container-fluid loginForm">
                                {/* Error msg remains */}
                                <div className='form-group mb-2'>
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        className='form-control border border-secondary form-input mb-3'
                                        value={name} onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        className='form-control border border-secondary form-input mb-3'
                                        value={email} onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        className='form-control border border-secondary form-input mb-3'
                                        value={password} onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <input
                                        type='password'
                                        placeholder='Confirm Password'
                                        className='form-control border border-secondary form-input mb-3'
                                        value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={`text-center ${darkmode ? "text-white" : "text-dark"}`}>
                                <span>
                                    Already have an account:&nbsp;
                                    <span className='text-secondary link-underline-dark changeModal' data-bs-target="#loginModal" data-bs-toggle="modal" id="backToLogin" >
                                        <u>Login</u>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="modal-footer">

                            <button className="col-12 btn btn-primary" data-bs-toggle="modal" onClick={() => handleRegister()} disabled={!canRegister}>
                                {buttonVal}
                            </button>
                            <button className="col-12 btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" id="closeRegisterModal" onClick={() => resetCredentials()}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register
