import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './hover.css'
import { setUserid, setUsername } from '../features/data/dataSlice';
// import Error from '../components/Error'
// import Spinner from '../components/Spinner'
import axios from 'axios';
import { setAuthToken } from '../utils/api';

function Login() {
    // Theme
    const darkmode = useSelector((state) => state.theme.darkmode);

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const canLogin = Boolean(email) && Boolean(password)

    const { language, code, filename, input, output } = useSelector((state) => ({
        language: state.data.language,
        code: state.data.code,
        filename: state.data.fileName,
        input: state.data.customInput,
    }))

    async function sendLoginCredentials() {
        const response = await axios.post('http://103.99.203.56:8000/login', { email, password })
        try {
            if (response.status === 200) {
                if (response.data.type === 'successLogin') {
                    const { token, userid, username } = response.data
                    localStorage.setItem('token', token)
                    localStorage.setItem('username', username)
                    localStorage.setItem('cP', -1)
                    dispatch(setUserid(userid));
                    dispatch(setUsername(username));
                    //setUseridState(userid)
                    await axios.post('http://103.99.203.56:8000/codeTempData', {
                        userid, language, code, filename, input
                    })
                    window.location.reload();
                    setAuthToken(token);
                } else if (response.data.type === "Email") {
                    console.log("Email Id does not exist")
                } else if (response.data.type === 'Password') {
                    console.log("Passwords do not match")
                } else {
                    console.log(response.data.message)
                }
            }
        } catch (error) {

        }
        resetCredentials()
        document.getElementById('closeLoginModal').click();
    }

    function handleLogin() {
        try {
            sendLoginCredentials()
            resetCredentials();
        } catch (error) {
            console.log("erroris: ", error)
        }
    };

    //Cancel Login
    function resetCredentials() {
        setEmail('');
        setPassword('');
    }

    return (
        <div div className="modal fade" id="loginModal" aria-hidden="true" aria-labelledby="loginModal" tabIndex="-1" >
            <div className="modal-dialog modal-dialog-centered">
                <div className={` modal-content shadow-lg ${darkmode ? "bg-dark" : ""}`}>
                    <div className="modal-header">
                        <h3 className={`col-12 modal-title text-center ${darkmode ? "text-white" : "text-dark"}`} id="loginModalText">
                            Login
                        </h3>
                    </div>
                    <form>
                        <div className="modal-body">
                            <div className="container-fluid loginForm">
                                {/* Error msg remains */}
                                <div className='form-group mb-2'>
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        className='form-control border border-secondary form-input mb-3'
                                        value={email} onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        className='form-control border border-secondary form-input mb-3'
                                        value={password} onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={`text-center ${darkmode ? "text-white" : "text-dark"}`}>
                                <span>
                                    Already have an account:&nbsp;
                                    <span className='text-secondary link-underline-dark changeModal' data-bs-target="#singUpModal" data-bs-toggle="modal" id="backToLogin" >
                                        <u>Register</u>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="modal-footer">

                            <button className="col-12 btn btn-primary" data-bs-target="" data-bs-toggle="modal" disabled={!canLogin} onClick={() => handleLogin()}>
                                Login
                            </button>
                            <button className="col-12 btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" id="closeLoginModal" onClick={() => resetCredentials()}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
