import React, { useState, useEffect } from 'react'
import drishLight from '../icons/drishLight.png';
import drishDark from '../icons/drishDark.png';
import { useDispatch, useSelector } from "react-redux";
import { setDarkTheme, setDefaultTheme } from "../../features/theme/themeSlice";
import Register from '../../Modals/Register';
import Login from '../../Modals/Login';
import { MoonIcon, SunIcon } from '../icons/Icons';

function Navbar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        if (loggedInUser === null) {
            setIsLoggedIn(false)
        } else {
            setIsLoggedIn(true)
        }
    }, []);

    const dispatch = useDispatch();

    const theme = useSelector(state => state.theme)
    const setDark = () => {
        dispatch(setDarkTheme());
    };
    const setDefault = () => {
        dispatch(setDefaultTheme());
    };

    return (
        <nav className="navbar navbar-expand-lg shadow-sm ">
            <div className="container-fluid d-xs-block justify-content-xs-center d-xsm-flex  justify-content-xsm-around">
                {/* Logo */}
                <a className="xsm-navbar-brand" target="_blank" rel="noreferrer" href="https://drishinfo.com/">
                    <img src={`${theme.darkmode ? drishLight : drishDark}`} alt="" width="30" height="24" className="d-inline-block align-text-top" />
                    {<span className={`d-none d-sm-inline-flex fs-5 ${theme.darkmode ? 'text-white' : 'text-dark'}`}>
                        Drish Infotech
                    </span>}
                </a>

                {/* Theme */}
                <div>
                    {localStorage.getItem('token') ?
                        <span className={`fs-5 mx-2 align-self-center ${theme.darkmode ? "text-white" : "text-dark"}`}>Hello {localStorage.getItem('username')}</span>
                        :
                        <span className={`fs-5 mx-2 align-self-center ${theme.darkmode ? "text-white" : "text-dark"}`}>Hello Guest</span>
                    }

                    {theme.darkmode ?
                        <button type="button" className="btn px-2 mx-1 btn-outline-light" onClick={setDefault} >
                            <div className="d-flex flex-col">
                                <SunIcon />                            </div>
                        </button>
                        :
                        <button type="button" className="btn px-2 mx-1 btn-outline-dark" onClick={setDark} >
                            <div className="d-flex flex-col">
                                <MoonIcon />
                            </div>
                        </button>}

                    {isLoggedIn ?
                        <>
                            <button className={`btn px-2 mx-1 ${theme.darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} type="button" data-bs-toggle="offcanvas" data-bs-target="#menuBar" aria-controls="offcanvasRight">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                </svg>
                            </button>
                        </>
                        :
                        <button type="button" className={`btn px-2 mx-1 ${theme.darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} id="account" data-bs-toggle="modal" data-bs-target="#loginModal" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                            </svg>
                        </button>
                    }
                </div>
                <Login />
                <Register />
            </div>
        </nav >
    )

}

export default Navbar 
