import React, { useEffect, useState } from 'react';
import Input from './components/Input/Input';
import Output from './components/Output/Output';
import Navbar from './components/Navbar/Navbar';
import LeftPane from './components/LeftPane/LeftPane';
import { useSelector, useDispatch } from "react-redux";
import UserMenu from './components/UserMenu/UserMenu';
import { setUserid, setUsername } from './features/data/dataSlice';
import { withOneTabEnforcer } from "react-one-tab-enforcer"
import axios from 'axios';
import { setStateFilename, setStateLanguage, setStateInput, setStateCode } from './features/data/dataSlice'

function App() {

  const dispatch = useDispatch()
  // Theme
  const darkmode = useSelector((state) => state.theme.darkmode);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [triggerTwohalf, setTriggerTwohalf] = useState(true)
  const [triggerTen, setTriggerTen] = useState(true)
  const [isOnline, setIsOnline] = useState(0)
  const currentProject = useState(useSelector((state) => state.data.currentProjCount))

  const { language, code, fileName, input, } = useSelector((state) => ({
    language: state.data.language,
    code: state.data.code,
    fileName: state.data.fileName,
    input: state.data.customInput
  }))

  async function funcSTP(id, cPCount) {
    return await axios.post(`http://103.99.203.56:8000/saveToProject`, {
      id,
      p_id: cPCount,
      language, code, fileName, input
    })
  }

  let id = 0

  // Set Credentials on Login
  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    if (loggedInUser !== null) {
      setIsLoggedIn(true)
      id = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId
      dispatch(setUserid(id))
      dispatch(setUsername(localStorage.getItem('username')))
    }
  }, []);

  // Timeout for codesave every 2.5 seconds and logout after 30 minutes inactivty
  useEffect(() => {
    if (localStorage.getItem('token')) {
      let timerThree, timerTen;

      function resetTimer() {
        /* Clear the previous interval */
        clearInterval(timerThree);
        clearInterval(timerTen);

        /* Set a new interval */
        setTriggerTwohalf(true)
        setTriggerTen(true)
        timerThree = setTimeout(startIdleTimerForTwoHalf, 2500);
        timerTen = setTimeout(startIdleTimerForTenMin, 1800000);
        // timerTen = setTimeout(startIdleTimerForTenMin, 1800000);
      }

      window.onload = resetTimer;
      window.onmousemove = resetTimer;
      window.onclick = resetTimer;
      window.onkeydown = resetTimer;

      async function sendData() {
        console.log("Sent data to codeTemp")
        const userid = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId
        await axios.post('http://103.99.203.56:8000/codeTempData', {
          userid, language, code, filename: fileName, input
        })
        const cPCount = localStorage.getItem('cP')
        if (cPCount > -1) {
          console.log("Sent data to Project")
          const sendCurrentData = funcSTP(userid, cPCount)
          if (sendCurrentData.status == 200) {
            if (sendCurrentData.type == "Userid") {
              return console.log("Invalid UserId")
            } else if (sendCurrentData.type == "ProjectNotFound") {
              return console.log("No such Project Found")
            } else {
              console.log("Successfully sent to previous project")
            }
          }
        }
      }

      async function startIdleTimerForTwoHalf() {
        if (triggerTwohalf === true) {
          console.log("Enter twoHalf func")
          await sendData()
          setTriggerTwohalf(false)
        }
      }

      async function startIdleTimerForTenMin() {
        if (triggerTen === true) {
          console.log("10 minute idle logout")
          await sendData()
          await axios.post('http://103.99.203.56:8000/logout', { id })
          localStorage.clear()
          window.location.reload()
          setTriggerTen(false)
        }
      }
    }

  }, []);


  // Handle BeforeUnload
  useEffect(() => {

    const handleBeforeUnload = async (event) => {
      console.log("Reached inside handle beforeunload function")
      if (localStorage.getItem('token')) {
        const id = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId
        const cPCount = localStorage.getItem('cP') ? localStorage.getItem('cP') : -2

        await axios.post('http://103.99.203.56:8000/codeTempData', {
          userid: id,
          language, code, filename: fileName, input
        })

        if (localStorage.getItem('cP') > -1) {
          const sendCurrentData = funcSTP(id, cPCount)
          if (sendCurrentData.status == 200) {
            if (sendCurrentData.type == "Userid") {
              return console.log("Invalid UserId")
            } else if (sendCurrentData.type == "ProjectNotFound") {
              return console.log("No such Project Found")
            } else {
              console.log("Successfully sent to previous project")
            }
          }
        }
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  // Handle Unload
  useEffect(() => {

    const handleUnload = async () => {
      if (localStorage.getItem('token')) {
        console.log("Reached inside handle unload function")
        const id = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId
        const response = await axios.post(`http://103.99.203.56:8000/getCodeTempData`, { id })

        dispatch(setStateFilename(response.data.message.fileName))
        dispatch(setStateLanguage(response.data.message.language))
        dispatch(setStateInput(response.data.message.input))
        dispatch(setStateCode(response.data.message.code))
      }
    }
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  // Is Online or Not?
  useEffect(() => {
    function handleOnlineStatus() {
      setIsOnline(true)
    }

    function handleOfflineStatus() {
      setIsOnline(false)
    }

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);

    return () => {
      window.addEventListener('online', handleOnlineStatus);
      window.addEventListener('offline', handleOfflineStatus);
    }
  }, [])


  return (
    <div className={`App ${darkmode ? 'bg-dark' : 'bg-white'}`} >
      {isLoggedIn && <UserMenu />}
      <Navbar />
      <div className="container-fluid mainContent mt-2">
        {currentProject > 0 ? <span>Current Project is {currentProject}</span> : ''}
        <div className="row p-3 leftRow">
          <div className="col-sm-12 col-lg-7 container">
            <LeftPane />
          </div>
          < div className="col-sm-12 col-lg-5 container rightRow">
            <div className='row h-50'>
              <Input />
            </div>
            <div className='row h-50'>
              <Output />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

const DifferentWarningComponent = () =>
  <div className='text-center'>
    <div>Currently we allow this website to be open in only one tab per browser</div>
    <div>Please close the previously opened tab to access a new session of this website</div>
    <div>Thank You</div>
  </div>


export default withOneTabEnforcer({
  appName: "my-unique-drish-online-compiler",
  OnlyOneTabComponent: DifferentWarningComponent,
  localStorageTimeout: 15 * 1000,
  localStorageResetInterval: 10 * 1000
})(App)