import { configureStore } from "@reduxjs/toolkit";
import themeSlice from "../features/theme/themeSlice";
import tokenSlice from "./tokenSlice";
import dataReducer from '../features/data/dataSlice';
import registerSlice from "../features/register/registerSlice";
import loginSlice from "../features/login/loginSlice";

const store = configureStore({
    reducer: {
        theme: themeSlice,
        data: dataReducer,
        token: tokenSlice,
        login: loginSlice,
        register: registerSlice
    }
});

export default store;