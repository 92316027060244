import React, { useState } from 'react'
import './Input.css'
import { useSelector, useDispatch } from 'react-redux';
import { setStateInput } from '../../features/data/dataSlice';

function Output() {

    const darkmode = useSelector((state) => state.theme.darkmode);
    const dispatch = useDispatch()
    const [inputValue, setInputValue] = useState(useSelector(state => state.data.customInput))
    const handleChange = e => {
        setInputValue(e.target.value)
        dispatch(setStateInput(e.target.value))
    }
    return (
        <div className='container' id="input">
            <h3 className={`${darkmode ? "text-white" : "text-dark"}`}>
                Input <span className='fs-6'>(Enter Separated)</span>
            </h3>
            <textarea
                className={`container-fluid ${darkmode ? 'textAreaBgDark text-white' : 'bg-light-subtle text-dark'}`}
                value={inputValue}
                onChange={handleChange}>

            </textarea>
        </div>
    )
}

export default Output
