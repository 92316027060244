import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

function NewProject() {

    //Theme
    const darkmode = ''
    const [newProjectTitle, setNewProjectTitle] = useState('')

    const { language, code, fileName, input, output } = useSelector((state) => ({
        language: state.data.language,
        code: state.data.code,
        fileName: state.data.fileName,
        input: state.data.customInput
    }))


    async function handleNewProject() {
        try {
            const type = localStorage.getItem('type')
            const id = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId
            const cp = localStorage.getItem('cP')
            // if save send code to tempData
            if (type == "save") {
                await axios.post('http://103.99.203.56:8000/codeTempData', {
                    userid: id, language, code, filename: fileName, input, cp
                })
            }

            //create new empty project irrespective of type
            const createProject = await axios.post(`http://103.99.203.56:8000/createNewProject`, { id, newProjectTitle, type })
            if (createProject.status == 200) {
                if (createProject.data.type === "UserInvalidity") {
                    return console.log("Invalid User")
                } else if (createProject.data.type === "ProjectTitleDuplicate") {
                    return console.log("Duplicate Project title Detected. Enter a new project Title")
                }
                else {
                    console.log("Project created")
                }
            }
            setNewProjectTitle('')

            // if save send data to the newly created project. For New Project, just create new Project, show its div but dont change content
            if (type == 'save') {
                const sendCurrentData = await axios.post(`http://103.99.203.56:8000/saveToProject`, {
                    id,
                    p_id: createProject.data.message,
                    language, code, fileName, input
                })
                if (sendCurrentData.status == 200) {
                    if (sendCurrentData.type == "Userid") {
                        return console.log("Invalid UserId")
                    } else if (sendCurrentData.type == "ProjectNotFound") {
                        return console.log("No such Project Found")
                    } else {
                        console.log("Successfully sent to previous project")
                        localStorage.setItem('cP', createProject.data.message)
                    }
                }
            }

            document.getElementById('closeNewProject').click()
            console.log("Successfully closed")
        } catch (error) {
            console.log("The error that occurred in New project is: ", error)
        }
    }

    return (
        <div className="modal fade" id="newProjModal" aria-hidden="true" aria-labelledby="newProjModal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`modal-content shadow-lg ${darkmode ? "bg-dark" : ""}`}>
                    <div className="modal-header ">
                        <h3 className={`col-12 modal-title text-center ${darkmode ? "text-white" : "text-dark"}`} id="loginModalText">Create New Project</h3>
                    </div>
                    <form >
                        <div className="modal-body">
                            <div className="container">
                                <div className='text-center'>
                                    <div className='mb-4'><b>Enter Project Title &#40;Maximum Length: 40&#41;</b></div>
                                </div>
                                <input
                                    type='text'
                                    placeholder='Enter Title Here (Max 20 charcaters)'
                                    value={newProjectTitle}
                                    className='form-control form-input border border-secondary mb-3 p-2'
                                    onChange={e => setNewProjectTitle(e.target.value)}
                                    required
                                    maxLength={20}
                                />
                            </div>

                            <div className="modal-footer justify-content-center mt-4">
                                <button type="button" className="col-12 btn btn-success" id="newProject" data-bs-toggle="modal" onClick={() => handleNewProject()}>
                                    Create Project
                                </button>
                                <button className="col-12 btn btn-secondary" data-bs-dismiss="modal" id="closeNewProject">
                                    <b>Cancel</b>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default NewProject
