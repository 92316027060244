import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setStateFilename, setStateLanguage, setStateCode, setStateInput, setConsoleOutput, setCurrentProjCount } from '../../features/data/dataSlice';
import './UserMenu.css'
import { PlusIcon, ProjectsLogo, PenIcon, BinIcon, SettingsLogo, CancelIcon, TickIcon, RefreshIcon } from '../icons/Icons';
import axios from 'axios';

function UserMenu() {
    const dispatch = useDispatch();

    //theme
    const darkmode = useSelector((state) => state.theme.darkmode);
    const id = localStorage.getItem('token') ? JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId : -1
    const cPCount = localStorage.getItem('cP') !== null ? localStorage.getItem('cP') : -2
    const username = localStorage.getItem("username") ? localStorage.getItem("username") : ''
    const [projectTitleData, setProjectTitleData] = useState([])
    const [newProjectTitle, setNewProjectTitle] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [toggleNamingProject, setToggleNamingProject] = useState(false)

    const { language, code, fileName, input } = useSelector((state) => ({
        language: state.data.language,
        code: state.data.code,
        fileName: state.data.fileName,
        input: state.data.customInput
    }))

    const onLoadingFunc = useCallback(async () => {
        try {
            const projectList = await axios.get(`http://103.99.203.56:8000/projectTitles`, {
                params: {
                    id: id,
                },
            })
            if (projectList.status === 200 && projectList.data.type === "Userid") {
                console.log("Invalid userid")
            } else {
                const newTitles = [...projectTitleData];
                projectList.data.forEach((newTitle) => {
                    newTitles.push(newTitle)
                })

                setProjectTitleData(newTitles)
            }
        } catch (error) {
            console.log("UserMenu onloading func error: ", error)
        }
    }, [])

    useEffect(() => {
        onLoadingFunc();
    }, [onLoadingFunc])

    // New Project Creation
    async function newProjectClick() {
        try {
            const type = 'newProj'
            //create new empty project irrespective of type
            const response = await axios.post(`http://103.99.203.56:8000/createNewProject`, { id, newProjectTitle, type })
            if (response.status == 200) {
                if (response.data.type === "UserInvalidity") {
                    console.log("Invalid User")
                } else if (response.data.type === "ProjectTitleDuplicate") {
                    console.log("Duplicate Project title Detected. Enter a new project Title")
                }
                else {
                    console.log("Project created")
                }
            }
        } catch (error) {
            console.log("NewProjectIconClick function in LeftPane.js error: ", error)
        }
        setNewProjectTitle('')
    }
    function cancelClick() {
        setToggleNamingProject(false)
        setNewProjectTitle('')
    }

    // Refresh Project List
    async function callRefresh() {
        await onLoadingFunc()
        console.log("Clicked on callRefresh")
    }

    async function funcSTP(id, cPCount) {
        const response = await axios.post(`http://103.99.203.56:8000/saveToProject`, {
            id,
            p_id: cPCount,
            language, code, fileName, input
        })
        const responseJson = { status: response.status, type: response.data.type, message: response.data.message }
        return responseJson
    }
    //Fetch Project TO BE COMPLETED for cp=-1
    async function fetchProject(new_PID) {
        try {
            setSearchValue('')
            if (localStorage.getItem('cP') != new_PID) {
                if (localStorage.getItem('cP') > 0) {
                    const response = await funcSTP(id, cPCount)
                    if (response.status == 200) {
                        if (response.type == "Userid") {
                            return console.log("Invalid UserId")
                        } else if (response.type == "ProjectNotFound") {
                            return console.log("No such Project Found")
                        } else {
                            console.log("Successfully sent to previous project")
                        }
                    }
                }
                const response = await axios.get(`http://103.99.203.56:8000/projectData`, {
                    params: {
                        id: id,
                        current_PID: cPCount,
                        new_PID
                    },
                })
                if (response.status === 200) {
                    if (response.data.type === "userid") {
                        console.log("Invalid Userid")
                    } else {
                        console.log("Success")
                        setSearchValue('')
                        localStorage.setItem('cP', new_PID);
                        dispatch(setCurrentProjCount(new_PID))
                        dispatch(setStateCode(response.data.message.p_code))
                        dispatch(setStateLanguage(response.data.message.p_language))
                        dispatch(setStateFilename(response.data.message.p_fileName))
                        await axios.post('http://103.99.203.56:8000/codeTempData', {
                            userid: id,
                            language, code, filename: fileName, input
                        })
                    }

                }
            } else {
                console.log("Project title chosen is the same as the current title")
            }
        } catch (error) {
            console.log("Error in fetchProject in userMenu: ", error)
        }

    }

    //Confirm Delete Project
    async function deleteProject(delPid) {
        //Here
        try {
            if (cPCount === delPid) {
                dispatch(setStateCode(''))
                dispatch(setStateLanguage('undefined'))
                dispatch(setStateFilename('main'))
                dispatch(setStateInput(''))
                dispatch(setConsoleOutput(''))
                localStorage.setItem('cP', -1)
            }
            const response = await axios.post('http://103.99.203.56:8000/deleteProject', { id, delPid })
            if (response.status === 200) {
                if (response.data.type === "Userid") {
                    console.log("Invalid userid")
                } else if (response.data.type === "ProjectNotFound") {
                    console.log("Project could not be found")
                } else {
                    const newList = projectTitleData.filter((item) => item.p_id !== delPid);
                    setProjectTitleData(newList);
                }
            }
        } catch (error) {
            console.log("Delete Project catch error: ", error)
        }
    }
    //Delete Project
    async function confirmDeleteProject(delPid) {
        try {
            let confirmDel = prompt("Do You wish to permanently delete this project?\nIf yes, type 'Confirmed' in the prompt below");
            if (confirmDel == null || confirmDel == "") {
                console.log('Key field is empty')
            }
            else if (confirmDel === 'Confirmed') {
                console.log("Project Deletion Confirmed")
                deleteProject(delPid)
            }
            else {
                console.log("Keys do not match")
                confirmDeleteProject(delPid)
            }
        } catch (error) {
            console.log("NewProjectIconClick function in LeftPane.js error: ", error)
        }
    }

    //Rename Project
    async function renameProjPrompt(rename_pid) {
        let newTitle = prompt("Enter new name for Project");
        if (newTitle == null || newTitle == "") {
        } else {
            renameProject(rename_pid, newTitle)
        }
    }

    async function renameProject(rename_id, newTitle) {
        const response = await axios.post('http://103.99.203.56:8000/renameProject', { id, rename_id, newTitle })
        if (response.data.type === "Userid") {
            console.log("Invalid User Id")
        } else {
            document.getElementById(`${rename_id}_Title`).innerText = newTitle
            console.log("Clicked on Rename Icon")
        }
    }

    //Logout Function 
    async function logoutFunc() {
        if (localStorage.getItem('cP') > 0) {
            const response = await funcSTP(id, cPCount)
            if (response.status == 200) {
                if (response.type == "Userid") {
                    return console.log("Invalid UserId")
                } else if (response.type == "ProjectNotFound") {
                    return console.log("No such Project Found")
                } else {
                    console.log("Successfully sent to previous project")
                }
            }
        }
        await axios.post('http://103.99.203.56:8000/logout', { id })
        localStorage.clear()
        window.location.reload()
    }

    //Delete User Account
    async function delAccoutFunc() {
        let person = prompt("Deleting your account is a permamnent action including loss of data, projects and account.\nIf you wish to proceed type 'Confirm_Delete' in the prompt below");
        if (person == 'Confirm_Delete') {
            await actualDelAccountFunc()
        }
    }

    async function actualDelAccountFunc() {
        if (localStorage.getItem('cP') > 0) {
            const response = await funcSTP(id, cPCount)
            if (response.status == 200) {
                if (response.type == "Userid") {
                    return console.log("Invalid UserId")
                } else if (response.type == "ProjectNotFound") {
                    return console.log("No such Project Found")
                } else {
                    console.log("Successfully sent to previous project")
                }
            }
        }
        await axios.post('http://103.99.203.56:8000/delete', { id })
        localStorage.clear();
        window.location.reload()
    }

    return (
        <div className={`offcanvas offcanvas-end ${darkmode ? 'bg-dark' : 'bg-white'} `} tabIndex="-1" id="menuBar" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header pb-0">
                <button type="button" className="btn-close" id="closeMenu" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setNewProjectTitle(false)}></button>
            </div>
            {/* Body Below */}
            <div className="offcanvas-body">
                {/* Initials and Name */}
                <figure>
                    {username.charAt(0).toUpperCase()}
                </figure>
                <div className={`text-center mb-2 fs-3 ${darkmode ? 'text-white' : 'text-dark'}`}>
                    {username}
                </div>
                <hr className='mt-1 mb-2' />
                {/* Projects */}
                <div className='d-flex justify-content-between'>
                    <div className="fs-4 d-flex justify-content-start">
                        <div className={`ms-3 me-2 ${darkmode ? 'white' : 'dark'}`}>
                            <ProjectsLogo />
                        </div>
                        <div className={`${darkmode ? 'text-white' : 'text-dark'}`}>
                            Projects
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <div>
                            <button type="button" className={`btn border-0 mr-1 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} onClick={() => callRefresh()} >
                                <RefreshIcon />
                            </button>
                        </div>
                        <div>
                            <button type="button" className={`btn border-0 mr-1 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} onClick={() => setToggleNamingProject(true)} >
                                <PlusIcon />
                            </button>
                        </div>
                    </div>
                </div>
                {toggleNamingProject &&
                    <div className='container'>
                        <div className={`fs-5 mb-1 ${darkmode ? 'text-white' : 'text-dark'}`}>
                            New Project
                        </div>
                        <div className='row'>
                            <div className="col-10">
                                <input
                                    placeholder='Enter New Project Title (Max 20 characters)'
                                    value={newProjectTitle}
                                    className='form-control form-input border border-secondary p-1'
                                    onChange={e => setNewProjectTitle(e.target.value)}
                                    required
                                    maxLength={20}
                                    type="text" />
                            </div>
                            <div className="col-1">
                                <button className='btn btn-dark p-0 m-0' disabled={!Boolean(newProjectTitle)} onClick={() => newProjectClick()}>
                                    <TickIcon />
                                </button>
                            </div>
                            <div className="col-1">
                                <button className='btn btn-dark p-0 m-0' onClick={() => cancelClick()}>
                                    <CancelIcon />
                                </button>
                            </div>
                        </div>
                        <hr className={`${darkmode ? 'border border-white' : 'border border-black'}`} />
                    </div>
                }

                <div className='container'>
                    <div className={`fs-5 mb-1 ${darkmode ? 'text-white' : 'text-dark'}`}>
                        Search
                    </div>
                    <input
                        placeholder='Search Project (Max 20 characters)'
                        value={searchValue}
                        className='form-control form-input border border-secondary mb-3 p-2 col-11'
                        onChange={e => setSearchValue(e.target.value)}
                        required
                        maxLength={20}
                        type="text"
                    />
                    <hr className={`${darkmode ? 'border border-white' : 'border border-black'}`} />
                </div>



                <div className='projectsList'>
                    {projectTitleData.length !== 0 &&
                        projectTitleData
                            .filter((dataValue) => {
                                return searchValue.toLowerCase() === ''
                                    ? dataValue
                                    : dataValue.p_title.toLowerCase().includes(searchValue);
                            })
                            .map((dataValue, index) => (
                                <button key={index} id={dataValue.p_id} iscurrent={dataValue.isCurrent} className={`btn ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'} container fs-5 py-2 ps-4 projectTitles d-flex justify-content-between`}>
                                    <div id={`${dataValue.p_id}_Title`} className={`${darkmode ? 'text-white' : 'text-dark'}`} onClick={() => fetchProject(dataValue.p_id)}>
                                        {dataValue.p_title}
                                    </div>
                                    <div className='showIcons'>
                                        <button className='btn p-0' onClick={() => renameProjPrompt(dataValue.p_id)}>
                                            <PenIcon />
                                        </button>
                                        <button className='btn p-0' onClick={() => confirmDeleteProject(dataValue.p_id)}>
                                            <BinIcon />
                                        </button>
                                    </div>
                                </button>
                            ))}

                    {projectTitleData.length === 0 &&
                        <div className='container mx-3'>
                            <i>
                                No Projects. Click on the Plus icon to create a New Project
                            </i>
                        </div>
                    }
                </div>

                <hr className='mt-1 mb-2' />
                {/* Settings */}
                <div className="fs-4 d-flex justify-content-start">
                    <div className='ms-3 me-2'>
                        <SettingsLogo />
                    </div>
                    <div className={`${darkmode ? 'text-white' : 'text-dark'}`}>
                        Settings
                    </div>
                </div>
                <hr className='mt-1 mb-2' />
                {/* Bottom Buttons */}
                <div className="mb-0">
                    <button className={`col-12 btn px-2 mx-2 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} type="button" onClick={() => logoutFunc()}>
                        LogOut
                    </button>
                    <button className={`col-12 btn px-2 mx-2 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} type="button" onClick={() => delAccoutFunc()}>
                        Delete Account
                    </button>

                    <button id="closeMenuBar" style={{ display: "none" }} data-bs-toggle="offcanvas" data-bs-target="#menuBar" ></button>
                    <button id="createProj" style={{ display: "none" }} data-bs-toggle="modal" data-bs-target="#newProjModal"></button>
                </div>
            </div>

        </div>
    )
}

export default UserMenu
