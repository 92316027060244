import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { setUserid } from '../data/dataSlice';
import axios from 'axios';

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    error: null,
};

const config = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    }
};

export const loginUser = createAsyncThunk(
    'login/loginUser',
    (credentials, { rejectWithValue }) => {
        try {
            const dispatch = useDispatch();
            console.log("Credentials: ", credentials)
            const response = axios.get('http://103.99.203.56:8000/login', credentials, config);
            console.log("Reached here 1")
            localStorage.setItem('token', response.data.token)
            const userid = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId
            dispatch(setUserid(userid));
            console.log("Reached here 2")
            return response.data.token;
        } catch (error) {
            console.log("Error is: ", error)
            return rejectWithValue(error.response.data);
        }
    }
);

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        resetLogin(state) {
            state.isLoggedIn = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isLoggedIn = true;
                state.error = null;
                console.log(action.payload)
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                console.log(action.payload)
            });
    },
});

export const { resetLogin } = loginSlice.actions;
export default loginSlice.reducer;
