import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
    name: 'myFeature',
    initialState: {
        isCurrentId: '0',
        id: '78',
        username: 'blank',
        email: '',
        downloadBoolean: false,
        uploadBoolean: false,
        saveBoolean: false,
        copyBoolean: false,
        code: 'print("Hello World")', //need to update as per language chosen
        consoleOutput: '',
        language: 'Python',
        customInput: '',
        fileName: 'main',
        gotToken: false,
        newProjectTitle: 'Unsaved',
        totalProjCount: 0,
        nonDeletedProjCount: 0,
        shouldUpdateProjectList: false,
        accessProjectTitleField: false,
        isConsoleDisabled: false,
        isInputDisabled: false,
        isEditorDisabled: false,
        currentProjCount: 0
    },
    reducers: {
        setIsCurrentId: (state, action) => {

            state.isCurrentId = action.payload
        },
        setUserid: (state, action) => {
            state.id = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload
        },
        setEmailData: (state, action) => {
            state.email = action.payload;
        },

        //Check four operations in options
        setDownloadBoolean: (state, action) => {
            state.downloadBoolean = action.payload;
        },
        setUploadBoolean: (state, action) => {
            state.uploadBoolean = action.payload;
        },
        setSaveBoolean: (state, action) => {
            state.saveBoolean = action.payload;
        },
        setCopyBoolean: (state, action) => {
            state.copyBoolean = action.payload;
        },
        setNewProjectTitle: (state, action) => {
            state.newProjectTitle = action.payload;
        },
        // set state for language, filename and code
        setStateCode: (state, action) => {
            state.code = action.payload;
        },
        setConsoleOutput: (state, action) => {
            state.consoleOutput = action.payload;
        },
        setStateFilename: (state, action) => {
            state.fileName = action.payload;
        },
        setStateLanguage: (state, action) => {
            state.language = action.payload;
        },
        setStateInput: (state, action) => {
            state.customInput = action.payload;
        },
        setGotToken: (state, action) => {
            state.gotToken = action.payload;
        },
        setTotalProjCount: (state, action) => {
            state.totalProjCount = action.payload;
        },
        setCurrentProjCount: (state, action) => {
            state.currentProjCount = action.payload;
        },
        setShouldUpdateProjectList: (state, action) => {
            state.shouldUpdateProjectList = action.payload;
        },
        setAccessProjectTitleField: (state, action) => {
            state.accessProjectTitleField = action.payload;
        },
        setIsInputDisabled: (state, action) => {
            state.isInputDisabled = action.payload;
        },
        setIsConsoleDisabled: (state, action) => {
            state.isConsoleDisabled = action.payload;
        },
        setIsEditorDisabled: (state, action) => {
            state.isEditorDisabled = action.payload;
        },
    },
});

export const { setIsCurrentId, setUsername, setUserid, setEmailData, setDownloadBoolean, setUploadBoolean, setSaveBoolean, setCopyBoolean, setStateCode, setConsoleOutput, setStateFilename, setStateLanguage, setStateInput, setGotToken, setTotalProjCount, setCurrentProjCount, setShouldUpdateProjectList, setAccessProjectTitleField, setIsConsoleDisabled, setIsEditorDisabled, setIsInputDisabled, setCallProjCount } = dataSlice.actions;

export default dataSlice.reducer;
