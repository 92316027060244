import React, { useState, useEffect } from 'react'
import './Output.css'
import { useSelector } from 'react-redux';

function Output() {

    const darkmode = useSelector((state) => state.theme.darkmode);
    const recievedOutput = useSelector((state) => state.data.consoleOutput)
    const [consoleText, setConsoleText] = useState('')

    useEffect(() => {
        setConsoleText(recievedOutput)
    }, [recievedOutput])

    return (
        <div className='container-fluid' id="console">
            <h3 className={`${darkmode ? "text-white" : "text-dark"}`}>Output</h3>
            <textarea readOnly={true} className={`container-fluid ${darkmode ? 'textAreaBgDark text-white' : 'bg-light-subtle text-dark'}`} value={consoleText}>
            </textarea>
        </div >
    )
}

export default Output
