// frontend/src/features/register/registerSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLoading: false,
    isRegistered: false,
    error: null,
};

export const registerUser = createAsyncThunk(
    'register/registerUser',
    async (userData, { rejectWithValue }) => {
        try {
            console.log("User data: ", userData)
            const response = await axios.post('http://103.99.203.56:8000/register', userData);
            if (response.status === 200) {
                if (response.data.type === 'Success') {
                    console.log("Successfully Registered")
                } else if (response.data.type === "Email") {
                    console.log("Email Id already esists")
                } else {
                    console.log("Passwords do not match")
                }
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        resetRegistration(state) {
            state.isRegistered = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state) => {
                state.isLoading = false;
                state.isRegistered = true;
                state.error = null;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { resetRegistration } = registerSlice.actions;

export default registerSlice.reducer;
