import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    id: 0
}

const tokenSlice = createSlice({
    name: "tokenPresence",
    initialState,
    reducers: {
        setLoginValues(state) {
            state.isLoggedIn = true
            state.id = localStorage.getItem("token")
        }
    },
});

export const { setLoginValues } = tokenSlice.actions;

export default tokenSlice.reducer;