import React, { useEffect } from 'react'
import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from '@codemirror/view';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { setDownloadBoolean, setUploadBoolean, setSaveBoolean, setCopyBoolean, setStateCode, setStateFilename, setStateLanguage } from '../../features/data/dataSlice';
import './CodeEditor.css'

// does not have c, go, swift
function CodeEditor(props) {
  const darkmode = useSelector((state) => state.theme.darkmode);
  const code = useSelector((state) => state.data.code);
  const onChange = (e) => {
    dispatch(setStateCode(e))
  }

  const languageSelect = new Map([
    ['undefined', 'undefined'],
    ['c', 'C',],
    ['cpp', 'C++'],
    ['go', 'Go'],
    ['java', 'Java'],
    ['js', 'JavaScript'],
    ['php', 'PHP'],
    ['py', 'Python'],
    ['swift', 'Swift'],
  ])

  const downloadState = useSelector((state) => state.data.downloadBoolean);
  const uploadState = useSelector((state) => state.data.uploadBoolean);
  const saveState = useSelector((state) => state.data.saveBoolean);
  const copyState = useSelector((state) => state.data.copyBoolean);
  const dispatch = useDispatch();

  let dataSliceFilename = useSelector((state) => state.data.fileName)

  // Download useEffect
  useEffect(() => {
    try {
      if (downloadState === true) {
        if (props.extension === 'undefined' || props.extension.length == 0) {
          console.log('Set Language')
        } else {
          if (!Boolean(dataSliceFilename)) {
            dataSliceFilename = 'main'
          }
          const filename = `${dataSliceFilename}.${props.extension}`;
          const blob = new Blob([code], { type: 'text/plain;charset=utf-8' });
          saveAs(blob, filename);
        }
        dispatch(setDownloadBoolean(false));
      }
    } catch (error) {
      console.log("Error while file Donwload: ", error)
    }

  }, [downloadState])

  // Upload useEffect - yet to finish
  function handleUploadedFile(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file)
    reader.onload = () => {
      console.log(file.name.split('.').pop().toLowerCase())
      dispatch(setStateLanguage(languageSelect.get(file.name.split('.').pop().toLowerCase())))
      dispatch(setStateFilename(file.name.substring(0, file.name.lastIndexOf("."))))
      dispatch(setStateCode(reader.result))
    }
    reader.onerror = () => {
      console.log('File Error: ', reader.error)
    }
  }

  useEffect(() => {
    if (uploadState === true) {
      document.getElementById('file').click()
      dispatch(setUploadBoolean(false))
    }
  }, [uploadState])

  // Save useEffect 
  useEffect(() => {
    if (saveState === true) {
      dispatch(setSaveBoolean(false));
    }
  }, [saveState])

  // Copy useEffect
  useEffect(() => {
    if (copyState === true) {
      navigator.clipboard.writeText(code)
      dispatch(setCopyBoolean(false));
    }
  }, [copyState])

  return (
    <div>
      <CodeMirror
        className="cm-outer-container"
        value={code}
        onChange={(e) => onChange(e)}
        height="75vh"
        extensions={[
          EditorView.lineWrapping
        ]}
        theme={`${darkmode ? 'dark' : 'light'}`}
      />
      <input type="file" name="" accept='.py, .c, .cpp, .php, .swift' id="file" onChange={e => handleUploadedFile(e)} />
    </div>
  );
}


export default CodeEditor;