import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    colors: {
        background: "#fff",
        button: "#fff",
        svg: "#fff",
        text: "#000",
    },
    darkmode: false,
}

const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setDarkTheme(state) {
            state.colors.header = "#324B50";
            state.colors.body = "#445155";
            state.darkmode = true;
        },
        setDefaultTheme(state) {
            state.colors.header = "#ebfbff";
            state.colors.body = "#fff";
            state.darkmode = false;
        },
    },
});

export const { setDarkTheme, setDefaultTheme } = themeSlice.actions;

export default themeSlice.reducer;