// This component represents the part in the left side of the body containg the filename inputs, all options like language, and [run, download, upload, copy, save] buttons as well as the code editor component
import React, { useState, useEffect } from 'react'
import CodeEditor from '../CodeEditor/CodeEditor';
import './LeftPane.css'
import { useSelector, useDispatch } from "react-redux";
import Login from '../../Modals/Login';
import { setDownloadBoolean, setUploadBoolean, setSaveBoolean, setCopyBoolean, setConsoleOutput, setStateFilename, setStateLanguage, setStateInput, setStateCode } from '../../features/data/dataSlice';
import { PlayIcon, MenuDownArrow, SaveIcon, CodingIcon, DownloadIcon, UploadIcon, CopyIcon, NewProjectIcon } from '../icons/Icons';
import axios from 'axios'
import NewProject from '../../Modals/NewProject';
import UserMenu from '../UserMenu/UserMenu'

function LeftPane() {
    const dispatch = useDispatch();

    //Theme
    const darkmode = useSelector((state) => state.theme.darkmode);

    //Language
    const extensionSelect = new Map([
        ['undefined', 'undefined'],
        ['C', 'c'],
        ['C++', 'cpp'],
        ['Go', 'go'],
        ['Java', 'java'],
        ['JavaScript', 'js'],
        ['PHP', 'php'],
        ['Python', 'py'],
        ['Swift', 'swift'],
    ])

    const { language, code, fileName, input, } = useSelector((state) => ({
        language: state.data.language,
        code: state.data.code,
        fileName: state.data.fileName,
        input: state.data.customInput
    }))

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [id, setId] = useState('')
    const cPCount = localStorage.getItem('cP') ? localStorage.getItem('cP') : -2
    const extension = extensionSelect.get(language)

    const handleFileNameChange = e => {
        dispatch(setStateFilename(e.target.value))
    }

    //See if logged in is true to enable options
    async function loadData(idTwo) {
        try {
            const response = await axios.post(`http://103.99.203.56:8000/getCodeTempData`, { id: idTwo })
            dispatch(setStateFilename(response.data.message.fileName))
            dispatch(setStateLanguage(response.data.message.language))
            dispatch(setStateInput(response.data.message.input))
            dispatch(setStateCode(response.data.message.code))
        } catch (error) {
            console.log("Error in LeftPane loadData TryCatch: ", error)
        }
    }

    useEffect(() => {
        if (localStorage.getItem("token") !== null) {
            setIsLoggedIn(true)
            setId(JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId)
            const idTwo = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).userId
            loadData(idTwo)
        }
    }, []);

    // handle Save (for loggedin users to save repos)
    const handleSave = async () => {
        localStorage.setItem('type', 'save')
        dispatch(setSaveBoolean(true));
        if (cPCount == -1) {
            document.getElementById('NewProj').click()
        } else {
            try {
                console.log("id: ", id)
                await axios.post('http://103.99.203.56:8000/codeTempData', {
                    userid: id, language, code, filename: fileName, input
                })
                const sendCurrentData = await axios.post(`http://103.99.203.56:8000/saveToProject`, {
                    id,
                    p_id: cPCount,
                    language, code, fileName, input
                })
                if (sendCurrentData.status == 200) {
                    if (sendCurrentData.type == "Userid") {
                        return console.log("Invalid UserId")
                    } else if (sendCurrentData.type == "ProjectNotFound") {
                        return console.log("No such Project Found")
                    } else {
                        console.log("Successfully sent to previous project")
                    }
                }
            } catch (error) {
                console.log("Save Button Else part error: ", error)
            }
        }
    }

    async function newProjectIconClick() {
        try {
            let newTitle = prompt("Enter Title for New Project");
            if (newTitle == null || newTitle === "") {
            }
            else if (newTitle.length > 20) {
                console.log("Title length more than 20 characters")
            }
            else {
                const type = 'newProj'
                //create new empty project irrespective of type
                const response = await axios.post(`http://103.99.203.56:8000/createNewProject`, { id, newProjectTitle: newTitle, type })
                if (response.status == 200) {
                    if (response.data.type === "UserInvalidity") {
                        console.log("Invalid User")
                    } else if (response.data.type === "ProjectTitleDuplicate") {
                        console.log("Duplicate Project title Detected. Enter a new project Title")
                    }
                    else {
                        console.log("Project created")
                    }
                }
            }
        } catch (error) {
            console.log("NewProjectIconClick function in LeftPane.js error: ", error)
        }

    }

    //handle Download (Working)
    const handleDownload = () => dispatch(setDownloadBoolean(true));

    //handle upload
    const handleUpload = () => {
        dispatch(setUploadBoolean(true));
    }

    // handle copy (Working)
    const handleCopy = () => dispatch(setCopyBoolean(true));

    //onClick Run button function
    const typedCode = useSelector((state) => state.data.code)
    const handleCodeSubmit = async () => {
        try {
            dispatch(setConsoleOutput(''))
            if (isLoggedIn) {
                console.log("Starting execution")
                await axios.post('http://103.99.203.56:8000/codeTempData', {
                    userid: id, language, code: typedCode, filename: fileName, input
                })
                    .then(async (res) => {
                        console.log("Successful received useriD response")
                        receivedUserId = res.data.id
                        await axios.post('http://103.99.203.56:8000/upload', {
                            typedCode, selectedLanguage: extension, userid: id, input,
                        }).then((res) => {
                            console.log(res.data.finalOutput)
                            dispatch(setConsoleOutput(res.data.finalOutput));
                        }).catch((err) => {
                            console.log("Some error occurred while uploading code: ", err)
                        })
                    }).catch((err) => {
                        console.log("Some error occurred in getting unique id", err)
                    })
                console.log("Finished Execution")
            } else {

                console.log('Starting Execution')
                var receivedUserId = 0
                await axios.get('http://103.99.203.56:8000/userId')
                    .then(async (res) => {
                        console.log("Successful received UserID response")
                        receivedUserId = res.data.id
                        await axios.post('http://103.99.203.56:8000/upload', {
                            typedCode,
                            selectedLanguage: extension,
                            userid: receivedUserId,
                            input,
                        }).then((res) => {
                            console.log(res.data.finalOutput)
                            dispatch(setConsoleOutput(res.data.finalOutput));
                        }).catch((err) => {
                            console.log("some error occurred", err)
                        })
                    }).catch((err) => {
                        console.log("some error occurred in getting unique id")
                    })
                console.log("Finished Execution")
            }
        } catch (error) {
            console.log("Error in post request: ", error)
        }
    }

    return (
        <div className="leftArea col">
            <div className="row d-flex justify-content-center">
                {/* Filename Input */}
                <input className={`col-4 col-sm-5 mb-1 rounded-start py-1 lh-1 ${darkmode ? 'border-white bg-dark text-white' : 'text-dark'}`} value={fileName} onChange={handleFileNameChange} maxLength="50" id="fileNameInput" />

                {/* Filename Extension Span */}
                <span className={`col-3 col-sm-2 mb-1 rounded-end p-0 text-white position-relative ${darkmode ? 'bg-light' : 'bg-dark'}`} disabled>
                    <p className={`position-absolute top-50 start-50 translate-middle ${darkmode ? 'text-dark' : 'text-white'}`}>.
                        {extension}
                    </p>
                </span>

                {/* Language Selection Dropdown*/}
                {/* <div className="btn col-3 dropdown-center languageSelector"> */}
                <button type="button" className={`btn col-3 dropdown-center border-0 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} id="langDdText" data-bs-toggle="dropdown" aria-expanded="false">
                    {/* <CodingIcon /> */}
                    <span className="h5 mr-5 d-none d-md-inline">Language</span>
                    <span className="h5 mr-5 d-none d-sm-inline d-md-none">Lang</span>
                    <span className="h5 mr-5 d-none d-sm-inline">
                        <MenuDownArrow />
                    </span>
                    <span className="h5 mr-5 d-sm-none">
                        <CodingIcon />
                    </span>

                </button>
                <ul className={`dropdown-menu langMenu ${darkmode ? 'darkList' : ''}`} aria-labelledby="dropdownMenuButton">
                    <li>
                        <button
                            className={`dropdown-item btn language ${darkmode ? 'darkListItem' : ''}`}
                            id='C'
                            onClick={() => {
                                dispatch(setStateLanguage('C'));
                                console.log('C')
                            }}>
                            C
                        </button>
                    </li>
                    <li>
                        <button
                            className={`dropdown-item btn language ${darkmode ? 'darkListItem' : ''}`}
                            id='C++'
                            onClick={() => {
                                dispatch(setStateLanguage('C++'));
                                console.log('C++')
                            }}>
                            C++
                        </button>
                    </li>

                    {/* <li>
                        <button
                            className={`dropdown-item btn language ${darkmode ? 'darkListItem' : ''}`}
                            id='Go'
                            onClick={() => langSelect('Go')}>
                            Go
                        </button>
                    </li>
                    <li>
                        <button
                            className={`dropdown-item btn language ${darkmode ? 'darkListItem' : ''}`}
                            id='Java'
                            onClick={() => langSelect('Java')}>
                            Java
                        </button>
                    </li>
                    <li>
                        <button
                            className={`dropdown-item btn language ${darkmode ? 'darkListItem' : ''}`}
                            id='JavaScript'
                            onClick={() => langSelect('JavaScript')}>
                            JavaScript
                        </button>
                    </li> */}

                    <li>
                        <button
                            className={`dropdown-item btn language ${darkmode ? 'darkListItem' : ''}`}
                            id='PHP'
                            onClick={() => {
                                dispatch(setStateLanguage('PHP'));
                                console.log('PHP')
                            }}>
                            PHP
                        </button>
                    </li>
                    <li>
                        <button
                            className={`dropdown-item btn language ${darkmode ? 'darkListItem' : ''}`}
                            id='Python'
                            onClick={() => {
                                dispatch(setStateLanguage('Python'));
                                console.log('Python')
                            }}>
                            Python
                        </button>
                    </li>
                    <li>
                        <button
                            className={`dropdown-item btn language ${darkmode ? 'darkListItem' : ''}`}
                            id='Swift'
                            onClick={() => {
                                dispatch(setStateLanguage('Swift'));
                                console.log('Swift')
                            }}>
                            Swift
                        </button>
                    </li>
                </ul>
                {/* </div> */}

                {/* Run Button*/}
                <button type="button" className={`btn col-1 border-0 px-2 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} onClick={handleCodeSubmit}>
                    <PlayIcon />
                </button>
            </div>
            <div className="row d-flex justify-content-center">
                {/* Download Button*/}
                <button type="button" className={`btn col-3 border-0 px-2 fs-5 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'} onHoverIcon`} onClick={handleDownload}>
                    <DownloadIcon />
                    <span className='d-none d-sm-block'>Download</span>
                </button>

                {/* Upload Button*/}
                <button type="button" className={`btn col-2 border-0 px-2 fs-5 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'} onHoverIcon`} onClick={handleUpload}>
                    <UploadIcon />
                    <span className='d-none d-sm-block'>Upload</span>
                </button>

                {/* Copy Button*/}
                <button type="button" className={`btn col-2 border-0 px-2 fs-5 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'} onHoverIcon`} onClick={handleCopy}>
                    <CopyIcon />
                    <span className='d-none d-sm-block'>Copy</span>
                </button>

                {/* Save Button*/}
                {isLoggedIn ?
                    <>
                        <button type="button" className={`btn col-2 border-0 px-2 fs-5 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'} onHoverIcon`} onClick={() => handleSave()}>
                            <SaveIcon />
                            <span className='d-none d-sm-block'>Save</span>
                        </button>
                        <button className={`btn col-3 border-0 px-2 fs-5 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} onClick={() => newProjectIconClick()}>
                            <NewProjectIcon />
                            <span className='d-none d-sm-block'>New Project</span>
                        </button>
                    </>
                    :
                    <button type="button" className={`btn col-3 border-0 px-2 fs-5 ${darkmode ? 'btn-outline-light' : 'btn-outline-dark'}`} data-bs-toggle="modal" data-bs-target="#loginModal">
                        <SaveIcon />
                        <span className='d-none d-sm-block'>Save</span>
                    </button>
                }
                <button id="NewProj" style={{ display: "none" }} data-bs-toggle="modal" data-bs-target="#newProjModal"></button>
                <button id="delProj" style={{ display: "none" }} data-bs-toggle="modal" data-bs-target="#confirmDelProjModal"></button>
                <button id="openMenuBar" style={{ display: "none" }} data-bs-toggle="modal" data-bs-target="#menuBar" aria-controls="offcanvasRight"></button>
            </div>
            <Login />
            <div id="printtext"></div>
            <CodeEditor
                fileName={fileName}
                extension={extension}
            />
            <NewProject />
            <UserMenu />
        </div>
    );
}

export default LeftPane
